<template>
  <div class="home">
    <div class="home-content">
      <div data-aos="fade-up" v-for="(item, index) in banners" :key="index">
        <div
          :class="
            activeBanner === index ? 'type-item activeBanner' : 'type-item'
          "
          @mouseenter="bannermouseover(index)"
          @mouseleave="activeBanner=-1"
          :style="item.position=='right'?{'justify-content':'flex-end'}:{}"   
          @click="$router.push({ path:`/c/lang/${item.categoryId}` })"
        >
        <img class="banner_bg" :src="item.imgPath" alt=""/>  
          <div class="item-text" :style="item.position=='right'?{'margin-right':'5vw'}:{}">
            <h2 class="title">{{item.name}}</h2>
            <p>{{item.description}}</p>
            <div class="more">
              <a href="javascript:;">
                <span>More</span>
              </a>
              <img src="../../assets/img/next@2x (2).png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <back-top></back-top>
  </div>
</template>

<script>
import { getHomeBanner } from "../../network/home";
import BackTop from "../../components/content/backTop/BackTop.vue";
export default {
  name: "Home",
  data() {
    return {
      activeBanner: -1,
      banners:[] //首页信息
    };
  },
  created() {
    this.getHomeBanners();
  },
  methods: {
    //获取首页信息
    getHomeBanners() {
      getHomeBanner().then((res) => {
        // console.log(res.data);
        this.banners=res.data
      });
    },
    //鼠标移入
    bannermouseover(index) {
      this.activeBanner = index;
    },
  },
  components: {
    BackTop,
  },
};
</script>

<style scoped lang="less">
@import "../../assets/css/home.less";
</style>
